<template>
    <v-container>
        <v-row class="mb-10">
            <h2>Настройка связей критериев оценивания с заданиями</h2>
        </v-row>
        
        <v-row justify="center" class="mb-10">
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="subject in subjects"
                    :key="subject.value"
                    :disabled="loadingStatuses.tasks"
                >
                    <v-expansion-panel-header>{{ subject.text }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="grade in subject.grades"
                                :key="grade"
                                :disabled="loadingStatuses.tasks"
                            >
                                <v-expansion-panel-header @click="fetchTasks(subject.text, grade)">{{ grade }} класс</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-progress-linear v-if="isLoadingGoingOn && (!tasks[subject.text] || !tasks[subject.text][grade])" indeterminate class="mb-5" />
                                    
                                    <v-expansion-panels v-if="tasks[subject.text] && tasks[subject.text][grade]">
                                        <v-expansion-panel
                                            v-for="(taskGroup, taskN) in tasks[subject.text][grade]"
                                            :key="taskN"
                                            :disabled="loadingStatuses.scoringCriterias"
                                        >
                                            <v-expansion-panel-header class="d-flex align-center">
                                                <span class="mr-5 mt-n6" style="flex-grow: 0;">Задание {{ taskN }}</span>
                                                <div class="d-flex align-center" @click.stop>
                                                    <div
                                                        v-for="subtaskN in getMaxSubtasksCountInTaskGroup(taskGroup)"
                                                        :key="subtaskN"
                                                    >
                                                        <v-autocomplete
                                                            :key="taskGroupSelectUpdateKey"
                                                            :value="getTotalScoringCriteria(taskGroup, subtaskN - 1)"
                                                            :items="scoringCriterias"
                                                            :label="`Подзадание ${subtaskN}`"
                                                            :hint="getTotalScoringCriteriaSublabel(taskGroup, subtaskN - 1)"
                                                            :disabled="locks[`${subject.text}_${grade}_${taskN}`]"
                                                            persistent-hint
                                                            dense
                                                            outlined
                                                            class="mr-5"
                                                            @change="onTotalScoringCriteriaChange(subject.text, grade, taskN, subtaskN - 1, $event)"
                                                        ></v-autocomplete>
                                                    </div>
                                                    <v-icon
                                                        :key="`key_${taskGroupSelectUpdateKey}`"
                                                        title="Переключатель блокировки селектора"
                                                        class="mr-5 mt-n6"
                                                        @click.prevent="toggleLock(subject.text, grade, taskN)"
                                                    >
                                                        mdi-lock{{locks[`${subject.text}_${grade}_${taskN}`] ? '' : '-open'}}-outline
                                                    </v-icon>
                                                </div>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="mt-5">
                                                <div
                                                    v-for="task in taskGroup"
                                                    :key="task.option"
                                                    class="d-flex align-center mb-5 w-100"
                                                >
                                                    <span class="mr-5">Вариант {{ task.option }}</span>
                                                    <div
                                                        v-for="(scoringCriteriaId, index) in task.subtasks"
                                                        :key="index"
                                                    >
                                                        <v-autocomplete
                                                            key="taskGroupSelectUpdateKey"
                                                            v-model="task.subtasks[index]"
                                                            :items="scoringCriterias"
                                                            outlined
                                                            hide-details
                                                            dense
                                                            :label="`Подзадание ${index + 1}`"
                                                            class="mr-5"
                                                            @change="updateChangeList(task.id, index, $event)"
                                                        ></v-autocomplete>
                                                    </div>
                                                </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>

        <v-row>
            <v-spacer />
            <v-btn 
                small
                outlined
                to="/scoring-criteria"
                :color="$const.color.primary"
                class="mr-5"
            >
                <v-icon left dark>
                    mdi-arrow-left
                </v-icon>
                Вернуться к списку
            </v-btn>
            <v-btn 
                small
                outlined 
                :color="$const.color.primary" 
                :dark="!!Object.keys(changeList).length"
                :disabled="Object.keys(changeList).length === 0"
                @click="dialogs.save = true"
            >
                Сохранить изменения
            </v-btn>
        </v-row>


        <v-dialog
            v-if="dialogs.save"
            :value="true"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-toolbar dark :color="$const.color.primary">
                    <v-toolbar-title>Сохранение изменений</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <p
                        v-for="(row, index) in getDescribedChangeList()"
                        :key="index"
                    >{{ row }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn outlined color="warning" :loading="loadingStatuses.save" @click.prevent.stop="dialogs.save = false">
                        Отмена
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :color="$const.color.primary" :loading="loadingStatuses.save" dark @click.prevent.stop="save">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'

const errorMessage = 'При сохранении данных произошла ошибка. Обратитесь к администратору';
const successMessage = 'Успешно сохранено!';

export default {
    name: 'TaskRelations',
    data () {
        return {
            loadingStatuses: {
                tasks: false,
                scoringCriterias: false,
                save: false
            },
            dialogs: {
                save: false
            },
            locks: {},
            tasks: {},
            changeList: {},
            scoringCriterias: [],
            subtasksToScoringCriterias: [],
            taskGroupSelectUpdateKey: 0
        }
    },
    computed: {
        ...mapState('job', ['subjects']),
        isLoadingGoingOn () {
            return !!Object.keys(this.loadingStatuses).reduce((summ, key) => +this.loadingStatuses[key] + summ, 0);
        },
        isUserCan () {
            return ['Кудрявцев', 'admin'].includes(this.$store.state.user?.profile?.name);
        }
    },
    created () {
        this.fetchScoringCriterias()
    },
    methods: {
        getFlatArrayOfTasks () {
            const tasks = [];
            for (const subject in this.tasks) {
                for (const grade in this.tasks[subject]) {
                    for (const taskN in this.tasks[subject][grade]) {
                        for (const task of this.tasks[subject][grade][taskN]) {

                           tasks.push({
                                ...task,
                                subject,
                                grade
                           })
                        }
                    }
                }
            }
            return tasks;
        },
        getDescribedChangeList () {
            const results = [];
            const tasks = this.getFlatArrayOfTasks();

            for (const taskId in this.changeList) {

                const task = tasks.find(t => t.id === +taskId);

                for (const subtaskIndex in this.changeList[taskId]) {
                    
                    const scoringCriteria = this.scoringCriterias.find(sc => sc.value === this.changeList[taskId][subtaskIndex]);
                    const subtaskName = `${task.subject} ${task.grade} номер ${task.task} вариант ${task.option} подзадание ${+subtaskIndex + 1}`;
                    scoringCriteria?.text ?
                        results.push(`Изменено: '${subtaskName}' - задан критерий оценивания ${scoringCriteria.text}`) :
                        results.push(`Изменено: '${subtaskName}' - удален критерий оценивания`);
                }
            }
            return results;
        },
        async fetchScoringCriterias () {
            
            this.loadingStatuses.scoringCriterias = true;
            try {
                const { data } = await this.$store.dispatch(`hint/list`, {
                    filter: { type: 'subtask-scoring-criteria' },
                    pagination: 0
                });
            
                if (data?.items?.length)
                    this.scoringCriterias = data.items.map(item => ({ text: item.name, value: item.id }));
            } catch (e) {
                console.error(e);
            } finally {
                this.loadingStatuses.scoringCriterias = false;
            }
        },
        async fetchTasks (subject, grade) {
            if(!subject || !grade || this.tasks[subject]?.[grade]) { return false; }
            
            this.loadingStatuses.tasks = true 
            try {

                const { data } = await this.$store.dispatch(`task/list`, {
                    pagination: 0,
                    filter: { subject, grade },
                    fields: 'id,subtasks_count,task,option',
                    sort: { task: 'DESC' }
                });
                
                // Подгружаем данные из таблицы соответствий подсказок к подзаданиям
                if (data?.items?.length) {
                    const { data: subtasksToScoringCriterias } = await this.$store.dispatch('hint_of_subtask/list', {
                        pagination: 0,
                        filter: JSON.stringify(['IN', 'hint_of_subtask.task_id', data?.items.map(item => item.id)])
                    })
                    this.subtasksToScoringCriterias.push(...subtasksToScoringCriterias.items)
                    this.setTasksToList(subject, grade, data.items);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.loadingStatuses.tasks = false;
            }
        },
        setTasksToList (subject, grade, items) {
            
            if (!this.tasks[subject])
                this.tasks[subject] = {};

            this.tasks[subject][grade] = _.groupBy(items, 'task');

            for (const key in this.tasks[subject][grade]) {
                
                this.tasks[subject][grade][key] = this.tasks[subject][grade][key]
                    // Наполняем данными о подзаданиях
                    .map(task => {
                        task.subtasks = new Array(task.subtasks_count)
                                            .fill(null)
                                            .map((el, index) => {
                                                // Bind scoring criterias to subtask instances
                                                const stsc = this.subtasksToScoringCriterias.find(item => item.task_id === task.id && item.subtask_index === index);
                                                return stsc?.hint_id || null;
                                            })
                        return task;
                    })
                    // Сортировка вариантов
                    .sort((a, b) => a.option - b.option)
                // Установка дефолтных значений блокировки
                const group = this.tasks[subject][grade][key];
                this.locks[`${subject}_${grade}_${group[0].task}`] = true;
            }
        },
        updateChangeList (taskId, subtaskIndex, newValue) {
            
            if (!this.changeList[taskId]) {
                this.changeList[taskId] = {}
            }
            this.changeList[taskId][subtaskIndex] = newValue;
            this.taskGroupSelectUpdateKey++;
        },
        onTotalScoringCriteriaChange (subject, grade, task, subtaskIndex, value) {
            // Заново блокируем поле ввода предотвращая случайные клики
            this.locks[`${subject}_${grade}_${task}`] = true;
            
            this.tasks[subject][grade][task].forEach(_task => {
                if (typeof _task.subtasks[subtaskIndex] !== 'undefined')
                    _task.subtasks[subtaskIndex] = value;
                this.updateChangeList(_task.id, subtaskIndex, value)
            })
        },
        toggleLock (subject, grade, taskN) {
            this.locks[`${subject}_${grade}_${taskN}`] = !this.locks[`${subject}_${grade}_${taskN}`];
            this.taskGroupSelectUpdateKey++;
        },
        getTotalScoringCriteria (taskGroup, subtaskIndex) {
            const uniqueValues = Array.from( new Set(
                                                        taskGroup
                                                            .filter(task => typeof task.subtasks[subtaskIndex] !== 'undefined')
                                                            .map(task => task.subtasks[subtaskIndex])
                                                    )
                                            );
            return uniqueValues.length === 1 ? uniqueValues[0] : '-';
        },
        getTotalScoringCriteriaSublabel (taskGroup, subtaskIndex) {
            const dictionary = {
                'object': 'Укажите значение, чтобы заполнить все варианты',
                'string': 'Используются разные значения среди вариантов',
                'number': `Общее значение подзаданий ${subtaskIndex + 1}`,
                'default': null
            };
            const valueType = typeof this.getTotalScoringCriteria(taskGroup, subtaskIndex);
            return dictionary[`${valueType}`] || dictionary.default;
        },
        getMaxSubtasksCountInTaskGroup (taskGroup) {
            return Math.max(...taskGroup.map(task => task.subtasks_count));
        },
        async save () {
            if (!this.isUserCan) { return false; }

            try {
                const promises = [];
                this.loadingStatuses.save = true;
                for (const taskId in this.changeList) {
                    for (const subtaskIndex in this.changeList[taskId]) {
                        
                        const hint_id = this.changeList[taskId][subtaskIndex];
                        let subtaskToScoringCriteria = this.subtasksToScoringCriterias.find(item => item.task_id === +taskId && item.subtask_index === +subtaskIndex);

                        if (subtaskToScoringCriteria) {
                            // Updating
                            subtaskToScoringCriteria = _.pick({...subtaskToScoringCriteria, hint_id}, ['hint_id', 'subtask_index', 'task_id', 'id']);
                        } else {
                            // Creating
                            subtaskToScoringCriteria = { task_id: +taskId, subtask_index: +subtaskIndex, hint_id };
                        }
                        promises[promises.length] = this.$store.dispatch(`hint_of_subtask/${subtaskToScoringCriteria.id ? 'update' : 'create'}`, subtaskToScoringCriteria);
                    }
                }
                const results = await Promise.all(promises);
                const failedRequest = results.find(r => r.success === false)
                this.changeList = {}
                if (failedRequest) {
                    console.error(failedRequest.error);
                    alert(errorMessage);
                    return false;
                }
                alert(successMessage);
            } catch (e) {

                console.error(e);
            } finally {

                this.loadingStatuses.save = false;
                this.dialogs.save = false
            }
        }
    }
}
</script>